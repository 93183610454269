.navigation-bar-dropdown-menu .ant-dropdown-menu {
  background: #1c1e28 !important;
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.87);
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.navigation-bar-dropdown-menu .ant-dropdown-menu li {
  padding: 10px 20px;
}
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
