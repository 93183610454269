@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Light.eot');
  src: url('./fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'), url('./fonts/Roboto-Light.woff2') format('woff2'), url('./fonts/Roboto-Light.woff') format('woff'), url('./fonts/Roboto-Light.ttf') format('truetype'), url('./fonts/Roboto-Light.svg#Roboto-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Bold.eot');
  src: url('./fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/Roboto-Bold.woff2') format('woff2'), url('./fonts/Roboto-Bold.woff') format('woff'), url('./fonts/Roboto-Bold.ttf') format('truetype'), url('./fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Medium.eot');
  src: url('./fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('./fonts/Roboto-Medium.woff2') format('woff2'), url('./fonts/Roboto-Medium.woff') format('woff'), url('./fonts/Roboto-Medium.ttf') format('truetype'), url('./fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.eot');
  src: url('./fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('./fonts/Roboto-Regular.woff2') format('woff2'), url('./fonts/Roboto-Regular.woff') format('woff'), url('./fonts/Roboto-Regular.ttf') format('truetype'), url('./fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'philipa-icons';
  src: url('fonts/philipa-icons.eot?b1d3i9');
  src: url('fonts/philipa-icons.eot?b1d3i9#iefix') format('embedded-opentype'), url('fonts/philipa-icons.ttf?b1d3i9') format('truetype'), url('fonts/philipa-icons.woff?b1d3i9') format('woff'), url('fonts/philipa-icons.svg?b1d3i9#philipa-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'philipa-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icn-image-between-lines:before {
  content: '\e9e3';
}
.icn-image-inline:before {
  content: '\e9e4';
}
.icn-library-add-group:before {
  content: '\e9e0';
}
.icn-library-add-shared:before {
  content: '\e9e1';
}
.icn-library-add-user:before {
  content: '\e9e2';
}
.icn-hide-panel:before {
  content: '\e9dd';
}
.icn-patent-pulse:before {
  content: '\e9de';
}
.icn-show-panel:before {
  content: '\e9df';
}
.icn-custom-numbered-list:before {
  content: '\e9db';
}
.icn-insert-end:before {
  content: '\e9dc';
}
.icn-add-column-left:before {
  content: '\e9d3';
}
.icn-add-column-right:before {
  content: '\e9d4';
}
.icn-add-row-above:before {
  content: '\e9d5';
}
.icn-add-row-below:before {
  content: '\e9d6';
}
.icn-delete-column:before {
  content: '\e9d7';
}
.icn-delete-row:before {
  content: '\e9d8';
}
.icn-merge-cells:before {
  content: '\e9d9';
}
.icn-split-cells:before {
  content: '\e9da';
}
.icn-add-comment:before {
  content: '\e9d1';
}
.icn-ai:before {
  content: '\e9d2';
}
.icn-bug:before {
  content: '\e9d0';
}
.icn-chemical:before {
  content: '\e9a4';
}
.icn-math:before {
  content: '\e9bb';
}
.icn-node-outlined:before {
  content: '\e9c2';
}
.icn-node-single-outlined:before {
  content: '\e9c8';
}
.icn-insert-above-dependent:before {
  content: '\e9c9';
}
.icn-insert-below-dependent:before {
  content: '\e9ca';
}
.icn-extract-text:before {
  content: '\e9c6';
}
.icn-linkedin-solid:before {
  content: '\e9cb';
}
.icn-replace-file:before {
  content: '\e9c7';
}
.icn-chevron-left:before {
  content: '\e959';
}
.icn-chevron-right:before {
  content: '\e95a';
}
.icn-chevron-up:before {
  content: '\e95b';
}
.icn-chevron-down:before {
  content: '\e958';
}
.icn-left-outlined:before {
  content: '\e9a1';
}
.icn-right-outlined:before {
  content: '\e9ad';
}
.icn-left-solid:before {
  content: '\e9a2';
}
.icn-right-solid:before {
  content: '\e9ae';
}
.icn-up-solid:before {
  content: '\e9b6';
}
.icn-down-solid:before {
  content: '\e9b8';
}
.icn-collapse-all:before {
  content: '\e99c';
}
.icn-sort-default:before {
  content: '\e95d';
}
.icn-sort-ascending:before {
  content: '\e95c';
}
.icn-sort-descending:before {
  content: '\e95e';
}
.icn-arrow-left:before {
  content: '\e955';
}
.icn-arrow-right:before {
  content: '\e956';
}
.icn-arrow-up:before {
  content: '\e957';
}
.icn-arrow-down:before {
  content: '\e954';
}
.icn-sort-az-descending:before {
  content: '\e9af';
}
.icn-sort-az-ascending:before {
  content: '\e9b0';
}
.icn-add:before {
  content: '\e95f';
}
.icn-add-outlined:before {
  content: '\e960';
}
.icn-add-solid:before {
  content: '\e998';
}
.icn-remove:before {
  content: '\e9ab';
}
.icn-remove-outlined:before {
  content: '\e988';
}
.icn-remove-solid:before {
  content: '\e989';
}
.icn-clear:before {
  content: '\e965';
}
.icn-close-outlined:before {
  content: '\e966';
}
.icn-edit:before {
  content: '\e96e';
}
.icn-delete:before {
  content: '\e968';
}
.icn-duplicate:before {
  content: '\e96d';
}
.icn-copy:before {
  content: '\e967';
}
.icn-paste-line:before {
  content: '\e9b5';
}
.icn-save:before {
  content: '\e98c';
}
.icn-search:before {
  content: '\e98d';
}
.icn-filter-outlined:before {
  content: '\e974';
}
.icn-filter:before {
  content: '\e973';
}
.icn-multi-level-sort:before {
  content: '\e984';
}
.icn-upload:before {
  content: '\e992';
}
.icn-upload-outlined:before {
  content: '\e993';
}
.icn-download:before {
  content: '\e969';
}
.icn-download-outlined:before {
  content: '\e96a';
}
.icn-download-solid:before {
  content: '\e96b';
}
.icn-import:before {
  content: '\e977';
}
.icn-export:before {
  content: '\e96f';
}
.icn-refresh:before {
  content: '\e987';
}
.icn-retry:before {
  content: '\e98a';
}
.icn-retry-special:before {
  content: '\e9ac';
}
.icn-external-link:before {
  content: '\e970';
}
.icn-preview:before {
  content: '\e986';
}
.icn-translate:before {
  content: '\e990';
}
.icn-split:before {
  content: '\e98e';
}
.icn-locate:before {
  content: '\e97e';
}
.icn-bookmark-outlined:before {
  content: '\e962';
}
.icn-bookmark:before {
  content: '\e961';
}
.icn-bookmarks-outlined:before {
  content: '\e964';
}
.icn-bookmarks:before {
  content: '\e963';
}
.icn-like-outlined:before {
  content: '\e97b';
}
.icn-like-solid:before {
  content: '\e97a';
}
.icn-favorite-outlined:before {
  content: '\e972';
}
.icn-favorite:before {
  content: '\e971';
}
.icn-locked-outlined:before {
  content: '\e980';
}
.icn-locked:before {
  content: '\e97f';
}
.icn-unlocked:before {
  content: '\e991';
}
.icn-visible:before {
  content: '\e994';
}
.icn-hidden:before {
  content: '\e976';
}
.icn-insert-below:before {
  content: '\e979';
}
.icn-insert-above:before {
  content: '\e978';
}
.icn-drag:before {
  content: '\e96c';
}
.icn-more-horizontal:before {
  content: '\e982';
}
.icn-more-vertical:before {
  content: '\e983';
}
.icn-menu:before {
  content: '\e981';
}
.icn-list-view:before {
  content: '\e97c';
}
.icn-list-view-2:before {
  content: '\e97d';
}
.icn-grid-view:before {
  content: '\e975';
}
.icn-cmd:before {
  content: '\e953';
}
.icn-time-extention:before {
  content: '\e98f';
}
.icn-play:before {
  content: '\e9a8';
}
.icn-play-outlined:before {
  content: '\e9a9';
}
.icn-play-solid:before {
  content: '\e985';
}
.icn-pause:before {
  content: '\e9a6';
}
.icn-pause-outlined:before {
  content: '\e9a7';
}
.icn-pause-solid:before {
  content: '\e9ba';
}
.icn-previous:before {
  content: '\e9aa';
}
.icn-enter:before {
  content: '\e99e';
}
.icn-thumbs-up:before {
  content: '\e9a3';
}
.icn-thumbs-down:before {
  content: '\e99d';
}
.icn-flag:before {
  content: '\e99f';
}
.icn-focus:before {
  content: '\e9b4';
}
.icn-comment:before {
  content: '\e9b3';
}
.icn-send:before {
  content: '\e9bc';
}
.icn-checkmark:before {
  content: '\e929';
}
.icn-done-outlined:before {
  content: '\e92a';
}
.icn-done-solid:before {
  content: '\e92b';
}
.icn-warning:before {
  content: '\e934';
}
.icn-error-outlined:before {
  content: '\e92d';
}
.icn-error-solid:before {
  content: '\e927';
}
.icn-question-mark:before {
  content: '\e930';
}
.icn-question-outlined:before {
  content: '\e931';
}
.icn-question-solid:before {
  content: '\e932';
}
.icn-info-outlined:before {
  content: '\e92f';
}
.icn-info-solid:before {
  content: '\e928';
}
.icn-image:before {
  content: '\e92e';
}
.icn-robot:before {
  content: '\e933';
}
.icn-error-alarm:before {
  content: '\e92c';
}
.icn-time-out:before {
  content: '\e9b2';
}
.icn-node:before {
  content: '\e9cc';
}
.icn-node-single:before {
  content: '\e9cd';
}
.icn-all-done:before {
  content: '\e9bf';
}
.icn-bold:before {
  content: '\e936';
}
.icn-underlined:before {
  content: '\e951';
}
.icn-italic:before {
  content: '\e93c';
}
.icn-strikethrough:before {
  content: '\e947';
}
.icn-superscript:before {
  content: '\e949';
}
.icn-subscript:before {
  content: '\e948';
}
.icn-bullet-list:before {
  content: '\e937';
}
.icn-numbered-list:before {
  content: '\e93f';
}
.icn-text-left:before {
  content: '\e94e';
}
.icn-text-center:before {
  content: '\e94c';
}
.icn-text-right:before {
  content: '\e94f';
}
.icn-line-spacing:before {
  content: '\e93d';
}
.icn-indent:before {
  content: '\e93b';
}
.icn-outdent:before {
  content: '\e940';
}
.icn-paragraph:before {
  content: '\e941';
}
.icn-text-color:before {
  content: '\e94d';
}
.icn-highlight-color:before {
  content: '\e93a';
}
.icn-text-style:before {
  content: '\e950';
}
.icn-table:before {
  content: '\e94b';
}
.icn-photo:before {
  content: '\e942';
}
.icn-symbol:before {
  content: '\e94a';
}
.icn-attachment:before {
  content: '\e935';
}
.icn-link:before {
  content: '\e93e';
}
.icn-slash:before {
  content: '\e946';
}
.icn-placeholder:before {
  content: '\e943';
}
.icn-dependency:before {
  content: '\e938';
}
.icn-reference:before {
  content: '\e945';
}
.icn-undo:before {
  content: '\e952';
}
.icn-redo:before {
  content: '\e944';
}
.icn-heading:before {
  content: '\e939';
}
.icn-history:before {
  content: '\e9bd';
}
.icn-rotate:before {
  content: '\e914';
}
.icn-marker:before {
  content: '\e912';
}
.icn-print:before {
  content: '\e913';
}
.icn-invert:before {
  content: '\e911';
}
.icn-selection:before {
  content: '\e915';
}
.icn-fit-to-screen:before {
  content: '\e910';
}
.icn-default-to-screen:before {
  content: '\e9b9';
}
.icn-move:before {
  content: '\e9c0';
}
.icn-file-outlined:before {
  content: '\e924';
}
.icn-file:before {
  content: '\e9b7';
}
.icn-files-outlined:before {
  content: '\e995';
}
.icn-file-add-outlined:before {
  content: '\e923';
}
.icn-document-outlined:before {
  content: '\e922';
}
.icn-folder:before {
  content: '\e925';
}
.icn-add-folder:before {
  content: '\e921';
}
.icn-folder-special:before {
  content: '\e926';
}
.icn-team:before {
  content: '\e90f';
}
.icn-calendar:before {
  content: '\e90a';
}
.icn-screen-settings:before {
  content: '\e90d';
}
.icn-note:before {
  content: '\e90c';
}
.icn-log-list:before {
  content: '\e90b';
}
.icn-share:before {
  content: '\e90e';
}
.icn-double-view:before {
  content: '\e98b';
}
.icn-triple-view:before {
  content: '\e9c1';
}
.icn-ids-tool:before {
  content: '\e904';
}
.icn-case-explorer:before {
  content: '\e902';
}
.icn-patent-analysis:before {
  content: '\e906';
}
.icn-text-block-library:before {
  content: '\e908';
}
.icn-archive:before {
  content: '\e901';
}
.icn-glossary:before {
  content: '\e903';
}
.icn-folder-bookmarked:before {
  content: '\e996';
}
.icn-user-avatar:before {
  content: '\e909';
}
.icn-notification-bell:before {
  content: '\e905';
}
.icn-apps:before {
  content: '\e900';
}
.icn-settings:before {
  content: '\e907';
}
.icn-admin-panel:before {
  content: '\e9ce';
}
.icn-user:before {
  content: '\e920';
}
.icn-change-stakeholder:before {
  content: '\e916';
}
.icn-phone:before {
  content: '\e91c';
}
.icn-email:before {
  content: '\e91a';
}
.icn-office:before {
  content: '\e91b';
}
.icn-title:before {
  content: '\e91f';
}
.icn-time:before {
  content: '\e91e';
}
.icn-chart:before {
  content: '\e917';
}
.icn-chart-2:before {
  content: '\e99b';
}
.icn-chart-pie:before {
  content: '\e918';
}
.icn-recycle:before {
  content: '\e91d';
}
.icn-combination:before {
  content: '\e919';
}
.icn-invention-team:before {
  content: '\e9a0';
}
.icn-patent-granted:before {
  content: '\e9a5';
}
.icn-best:before {
  content: '\e999';
}
.icn-tech-field:before {
  content: '\e9b1';
}
.icn-globe:before {
  content: '\e997';
}
.icn-bulb:before {
  content: '\e99a';
}
.icn-keyboard:before {
  content: '\e9cf';
}
.icn-flag-2:before {
  content: '\e9be';
}
.icn-prompt:before {
  content: '\e9c3';
}
.icn-prompt-solid:before {
  content: '\e9c4';
}
.icn-receiver:before {
  content: '\e9c5';
}
body {
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ant-typography .ant-typography-edit {
  opacity: 0;
  transition: opacity 0.2s linear;
}
.ant-typography:hover .ant-typography-edit {
  opacity: 1;
}
.ant-popover .ant-popover-inner {
  border-radius: 10px;
}
.ant-space {
  display: flex;
}
/* Tabs */
.ant-tabs .ant-tabs-tab-btn {
  opacity: 0.7;
  font-weight: normal;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  opacity: 1;
  color: white;
  font-weight: 400;
}
.ant-tabs.ant-tabs-top {
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.ant-tabs.ant-tabs-top .ant-tabs-content-holder {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.ant-tabs.ant-tabs-top .ant-tabs-content {
  height: 100%;
}
.ant-tabs.ant-tabs-top .ant-tabs-tabpane {
  height: 100%;
}
.ant-tabs.ant-tabs-top .ant-tabs-nav::before {
  border-bottom-color: transparent;
}
/* Scrollbar styles */
/* used in styledUtils */
/* used in styledUtils */
/* Works on Firefox */
* {
  scrollbar-width: inherit;
  scrollbar-color: #434854 rgba(0, 0, 0, 0.01);
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.01);
}
*::-webkit-scrollbar-thumb {
  background: #434854;
  width: 8px;
  border: none;
  border-radius: 4px;
}
*::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.01);
}
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}
/* Remove excess padding and border in Firefox 4+ */
::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/* Image.PreviewGroup styles (image gallery) */
.ant-image-preview .ant-image-preview-operations {
  background-color: rgba(0, 0, 0, 0.5);
}
.ant-image-preview .ant-image-preview-operations .ant-image-preview-operations-operation {
  color: #ffffff;
  margin-left: 0;
  margin-right: 20px;
}
.ant-image-preview .ant-image-preview-operations .ant-image-preview-operations-operation .ant-image-preview-operations-icon {
  font-size: 24px;
}
.ant-image-preview .ant-image-preview-switch-left,
.ant-image-preview .ant-image-preview-switch-right {
  background-color: #d5d5d5;
  color: #000000;
}
.ant-image-preview .ant-image-preview-switch-left {
  left: 20px;
}
.ant-image-preview .ant-image-preview-switch-right {
  right: 20px;
}
.pop-up-modal-mask,
.pop-up-element {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5000;
}
.pop-up-element {
  background-color: transparent;
  height: auto;
  width: auto;
}
.pop-up-element a:link,
.pop-up-element a:visited,
.pop-up-element a:hover,
.pop-up-element a:active {
  color: lightblue;
}
body::-webkit-scrollbar {
  width: 2em !important;
}
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
