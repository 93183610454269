.ant-select-customize-input.dropdown-select {
  height: 50px;
  width: 100%;
  background: rgba(103, 110, 127, 0.3);
  border-radius: 6px;
}
.ant-select-customize-input.dropdown-select .ant-select-selector {
  background-color: transparent;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: normal;
  font-size: 12px;
  padding-left: 20px;
}
.ant-select-customize-input.dropdown-select .ant-select-selection-search {
  display: none;
}
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
