.image-view {
  clear: both;
  display: inline-block;
  line-height: 0;
  margin: 2px;
  /* This should be synced to the constant IMAGE_MARGIN in JS. */
  max-width: 100%;
  text-align: center;
  vertical-align: text-top;
}
.image-view.ProseMirror-selectednode {
  outline: none;
}
.image-view.align-left {
  float: left;
  margin: 0 40px 20px 0;
}
.image-view.align-right {
  float: right;
  margin: 0 0 20px 40px;
}
.image-view.align-center {
  clear: both;
  display: block;
  float: none;
  margin: 20px 0;
}
.image-view-body-img-clip {
  display: inline-block;
  max-width: 100%;
  position: relative;
}
.image-view-body {
  clear: both;
  display: inline-block;
  max-width: 100%;
  position: relative;
  border-radius: 8px;
  margin: 0 1px;
}
.image-view-body.loading::before,
.image-placeholder::before {
  animation: animation_blink normal 800ms infinite ease-in-out;
  background-color: #cdcdcd;
  bottom: 0;
  content: '';
  cursor: wait;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  border-radius: 8px;
}
.image-view-body.error::before {
  background-color: #fff;
  outline: solid 1px #cdcdcd;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
.image-view-body .icon.error {
  color: red;
  left: 50%;
  position: absolute;
  top: 50%;
  z-index: 4;
  margin: -11px 0 0 -11px;
  font-size: 22px;
}
.image-view-body.selected::after {
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.image-view-body.active.selected::after {
  /* hide the highlight so user can resize the image easily. */
  display: none;
}
.image-view-body.active {
  background-color: transparent;
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);
}
.image-view-body.error {
  /* outline: var(--czi-border-red); */
}
.image-view-body.downloading::before {
  position: absolute;
  content: '';
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.image-view-body.hidden {
  display: none;
}
@media only print {
  .image-view-body.active {
    box-shadow: none;
  }
}
@keyframes animation_blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.image-resize-box {
  background-repeat: no-repeat;
  box-sizing: border-box;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  border-radius: 8px;
}
.image-resize-box-image {
  height: 100%;
  left: 0;
  opacity: 0.2;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.image-resize-box.left {
  bottom: auto;
  left: auto;
  right: 0;
  top: 0;
}
.image-resize-box.top_left {
  bottom: 0;
  left: auto;
  right: 0;
  top: auto;
}
.image-resize-box.top {
  bottom: 0;
  left: 0;
  right: auto;
  top: auto;
}
.image-resize-box.top_right {
  bottom: 0;
  left: 0;
  right: auto;
  top: auto;
}
.image-resize-box.right {
  bottom: auto;
  left: 0;
  right: auto;
  top: 0;
}
.image-resize-box.bottom_right {
  bottom: auto;
  left: 0;
  right: auto;
  top: 0;
}
.image-resize-box.bottom {
  bottom: auto;
  left: 0;
  right: auto;
  top: 0;
}
.image-resize-box.bottom_left {
  bottom: auto;
  left: auto;
  right: 0;
  top: 0;
}
.image-resize-box-control {
  height: 20px;
  position: absolute;
  width: 20px;
  z-index: 2;
}
.image-resize-box-control.top_left {
  cursor: nw-resize;
  left: -14px;
  top: -14px;
}
.image-resize-box-control.top_right {
  cursor: ne-resize;
  right: -14px;
  top: -14px;
}
.image-resize-box-control.bottom_right {
  cursor: se-resize;
  bottom: -14px;
  right: -14px;
}
.image-resize-box-control.bottom_left {
  cursor: sw-resize;
  bottom: -14px;
  left: -14px;
}
.image-resize-box-control::after {
  background-color: #4180cc;
  border-radius: 2px;
  box-sizing: border-box;
  content: '';
  height: 8px;
  left: 7px;
  position: absolute;
  top: 7px;
  width: 8px;
}
.image-resize-box-control::before {
  bottom: -10px;
  content: '';
  left: -10px;
  position: absolute;
  right: -10px;
  top: -10px;
}
.image-placeholder {
  width: 100px;
  height: 100px;
  display: inline-block;
  position: relative;
  vertical-align: text-top;
}
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
