@import 'prosemirror-flat-list/dist/style.css';
.color-editor {
  background: #fff;
  box-shadow: var(--overlay-shadow);
  font-family: var(--font-family);
  font-size: var(--font-size);
  padding: 4px 10px;
}
.color-editor .custom-button {
  border: solid 1px #fff;
  height: 18px;
  margin: 1px;
  padding: 0;
  width: 18px;
}
.color-editor .custom-button.color-editor-color-transparent {
  display: block;
  height: auto;
  margin: 2px;
  padding: 6px;
  text-align: center;
  width: auto;
}
.color-editor .custom-button.color-editor-color-transparent:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.color-editor .custom-button.active,
.color-editor .custom-button:hover {
  font-weight: normal;
  outline: solid 1px #000;
}
.color-editor-section {
  margin: 8px 0;
  width: 200px;
}
.color-editor-section .color-editor-cell {
  border-radius: 0;
}
.table-grid-size-editor {
  border-radius: 12px;
  background: linear-gradient(135deg, #3b3f4f 0%, #31323f 100%);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 20px 20px 16px 20px;
  font-size: 13px;
}
.table-grid-size-editor-body {
  position: relative;
}
.table-grid-size-editor-body::after {
  background: rgba(0, 0, 0, 0);
  content: '';
  left: 50px;
  position: absolute;
}
.table-grid-size-editor-cell {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
}
.table-grid-size-editor-cell.selected {
  background: #fff;
}
.table-grid-size-editor-footer {
  margin-top: 16px;
  text-align: center;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.87);
  font-weight: 500;
}
.text-selection {
  /* var(--selection-highlight-color-dark); */
  background: red;
}
.ProseMirror-focused {
  outline: none;
}
.ProseMirror {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: relative;
  user-zoom: fixed;
  border-radius: 1px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
  margin: 0 auto 0 auto;
  outline: none;
  z-index: 1;
}
.ProseMirror nobr {
  hyphens: none;
  white-space: nowrap;
}
.ProseMirror .tableWrapper {
  overflow-x: visible;
}
.ProseMirror.has-placeholder {
  min-height: 100px;
}
.prosemirror-editor-wrapper {
  position: relative;
}
.editor-content-placeholder {
  box-sizing: border-box;
  color: var(--placeholder-text-color);
  font-family: var(--content-font-family);
  font-size: var(--content-font-size);
  left: 0;
  line-height: var(--content-line-height);
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}
.ProseMirror.prosemirror-editor,
.ProseMirror[data-layout='us_letter_portrait'] {
  min-height: 279mm;
  padding: var(--doc-padding-default);
  width: var(--doc-width-us-letter-portrait);
}
.ProseMirror[data-layout='us_letter_landscape'] {
  min-height: 216mm;
  padding: var(--doc-padding-default);
  width: var(--doc-width-us-letter-landscape);
}
.ProseMirror[data-layout='desktop_screen_4_3'] {
  min-height: 90vh;
  padding: var(--doc-padding-default);
  width: var(--doc-width-us-desktop-screen-4-3);
}
.ProseMirror[data-layout='desktop_screen_16_9'] {
  min-height: 80vh;
  padding: var(--doc-padding-default);
  width: var(--doc-width-us-desktop-screen-16-9);
}
.prosemirror-editor-wrapper.embedded .ProseMirror {
  background: unset;
  border-radius: unset;
  box-shadow: unset;
  min-height: unset;
  padding: unset;
  width: unset;
}
@media only print {
  .ProseMirror.ProseMirror[class] {
    border-radius: unset;
    box-shadow: unset;
    max-width: 100vw;
    min-height: unset;
  }
}
/* Content Styles *************************************************************/
.ProseMirror p {
  line-height: var(--content-line-height);
  margin: 0;
}
.ProseMirror a:link,
.ProseMirror a:visited,
.ProseMirror a:hover,
.ProseMirror a:active {
  color: var(--content-link-color);
}
/* Blockquote */
.ProseMirror blockquote {
  background-color: var(--blockquote-background-color);
  border-left: var(--blockquote-border);
  border-radius: 1px;
  color: var(--blockquote-color);
  font-family: var(--blockquote-font-family);
  font-size: 1.4em;
  font-style: italic;
  line-height: 1.6;
  margin: 50px auto;
  padding: 1.2em 10% 1.2em 10%;
  position: relative;
}
/* CodeBlock */
.ProseMirror pre > code {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 20px;
}
/* Spacer */
.ProseMirror span[data-spacer-size] {
  display: inline-block;
  letter-spacing: 12px;
}
.ProseMirror span[data-spacer-size='tab'] {
  /* Google doc uses 50px as exact size for tab space. */
  /* The `letter-spacing` used here is actually the width of the spacer */
  /* Note that the actual rendered width will be 50px + width of the space */
  /* character. */
  letter-spacing: 50px;
}
.ProseMirror span[data-spacer-size='tab-large'] {
  /* Google doc uses 50px as exact size for tab space. */
  /* The `letter-spacing` used here is actually the width of the spacer */
  /* Note that the actual rendered width will be 50px + width of the space */
  /* character. */
  letter-spacing: 280px;
}
.ProseMirror hr[data-page-break] {
  height: 0;
  margin: 0;
  page-break-before: always;
  visibility: hidden;
}
.ProseMirror .font-size-mark {
  line-height: var(--content-line-height);
}
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
