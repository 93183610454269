/* Override general scrollbar styles */
.document-scroll-container {
  /* Works on Firefox */
  scrollbar-color: rgba(103, 110, 127, 0.5) #fafafa;
  /* Works on Chrome, Edge, and Safari */
}
.document-scroll-container::-webkit-scrollbar-track {
  background: #fafafa;
}
.document-scroll-container::-webkit-scrollbar-thumb {
  background: rgba(103, 110, 127, 0.5);
}
.document-scroll-container::-webkit-scrollbar-thumb:hover {
  background: #676e7f;
}
.document-scroll-container::-webkit-scrollbar-corner {
  background: #fafafa;
}
.ant-select-customize-input {
  background-color: transparent;
  padding: 0;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.ant-select-customize-input .ant-select-selection-search-input {
  display: none;
}
.ant-select-customize-input .ant-select-selector {
  width: 55px;
  position: relative;
  height: 20px;
  background-color: transparent;
  border: solid 1px rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  font-size: 12px;
}
.ant-select-customize-input .ant-select-selector .ant-select-selection-item {
  padding-right: 49px;
  line-height: 21px;
}
.document-view {
  flex: 1;
  cursor: grabbing;
  margin-bottom: 10px;
}
.ant-select-customize-input.document-selector {
  margin-bottom: 10px;
  width: 200px;
}
.ant-select-customize-input.document-selector .ant-select-selector {
  position: relative;
  height: 20px;
  width: auto;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  font-size: 12px;
}
.ant-select-customize-input.document-selector .ant-select-selector .ant-select-selection-search {
  display: none;
}
.document-info-tooltip {
  padding-top: 0;
}
.document-info-tooltip .ant-tooltip-inner {
  padding: 5px 8px;
  font-size: 11px;
  line-height: 1.63;
}
.document-info-tooltip .ant-tooltip-arrow {
  display: none;
}
.action-button-tooltip .ant-tooltip-inner {
  padding: 5px 8px;
  font-size: 11px;
  line-height: 1.63;
  min-height: 0;
}
.document-wrapper {
  flex: 1;
  display: flex;
  min-height: 0;
  position: relative;
  overflow: hidden;
}
.document-wrapper .react-pdf__message--no-data {
  flex: 1;
}
.toolbar-wrapper {
  container-type: inline-size;
}
.toolbar-wrapper .toolbar-content {
  gap: 5px;
}
@container (min-width: 550px) {
  .toolbar-wrapper > .toolbar-content {
    gap: 24px;
  }
  .toolbar-wrapper > .toolbar-content > div:first-child {
    min-width: 150px;
  }
}
.thumbnail-page-preview {
  border-radius: 4px;
  overflow: hidden;
}
.thumbnail-page-preview.thumbnail-page-preview--current {
  box-shadow: 0 0 0 3px #78a1f1, 0 0 0 4px rgba(255, 255, 255, 0.1);
}
.document-view-page {
  display: inline-block;
  background-color: transparent !important;
}
.annotations-layer-picker .ant-dropdown-menu-item {
  padding: 0 !important;
}
.annotations-layer-picker .ant-dropdown-menu-item:hover {
  background: transparent !important;
}
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
